@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// $candy-app-primary: mat-palette($mat-indigo);
// $candy-app-accent:  mat-palette($mat-pink, A200, A100, A400);

// Default colors

/* For use in src/lib/core/theming/_palette.scss */
$md-mcgpalette0: (
  50: #aef1ff,
  100: #d6f8ff,
  200: #9fe5ff,
  300: #6cbaff,
  400: #44d5ff,
  500: #1E90FF,
  600: #0f7fe0,
  700: #0e77d9,
  800: #0d6fd2,
  900: #0b619d,
  A100: #ecfdff,
  A200: #b4fdff,
  A400: #81fdff,
  A700: #69fdff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #ffffff,
  )
);

$my-app-primary: mat-palette($md-mcgpalette0, 700, 100, 800);
$my-app-accent: mat-palette($md-mcgpalette0, 700, 100, 800);

$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent);
@include angular-material-theme($my-app-theme);
